import React from 'react';

import Text from '@goodfynd/react-web.typography.text';
import type { ButtonLinkProps } from '@goodfynd/react-web.ui.button-link';
import { ButtonLink } from '@goodfynd/react-web.ui.button-link';

import { StyledActionContainer, StyledContainer, StyledImage } from './styles';

export default function CmsDashboardCta({
  ctas,
  description,
  image,
  title,
  ...props
}: CmsDashboardCtaProps) {
  return (
    <StyledContainer {...props}>
      {!!image && <StyledImage src={image} height={120} width={120} />}

      {!!title && (
        <Text alignment="center" as="h5" type="h5">
          {title}
        </Text>
      )}

      {!!description && (
        <Text alignment="center" marginTop={8} type="body1">
          {description}
        </Text>
      )}

      {ctas?.[0] && (
        <StyledActionContainer>
          {ctas.map(
            ({
              button_href = '',
              button_text,
              button_type,
              open_in_new_window,
            }) => (
              <ButtonLink
                href={button_href}
                key={button_text}
                target={open_in_new_window ? '_blank' : undefined}
                type={button_type as ButtonLinkProps['type']}
              >
                {button_text}
              </ButtonLink>
            )
          )}
        </StyledActionContainer>
      )}
    </StyledContainer>
  );
}
