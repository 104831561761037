import React from 'react';

import Text from '@goodfynd/react-web.typography.text';
import type { IconName } from '@goodfynd/react-web.ui.icon';
import Icon from '@goodfynd/react-web.ui.icon';
import { Image } from '@goodfynd/react-web.ui.image';

import {
  StyledBenefitContainer,
  StyledBenefitsContainer,
  StyledContainer,
  StyledGraphicContainer,
} from './styles';

import type { CmsBenefitsProps } from './types';

export default function CmsBenefits({
  benefits = [],
  header,
  ...props
}: CmsBenefitsProps): JSX.Element {
  return (
    <StyledContainer {...props}>
      {!!header && (
        <Text as="h5" marginBottom={24} type="h5">
          {header}
        </Text>
      )}

      <StyledBenefitsContainer {...props}>
        {benefits.map(({ description, icon, image }) => (
          <StyledBenefitContainer key={description}>
            {(!!icon || !!image) && (
              <StyledGraphicContainer>
                {!!icon && (
                  <Icon
                    color="GreenBrand"
                    name={icon.replace('icon-', '').trim() as IconName}
                    size={24}
                  />
                )}
                {!!image && (
                  <Image src={image} height={24} width={24} alt={description} />
                )}
              </StyledGraphicContainer>
            )}

            <Text>{description}</Text>
          </StyledBenefitContainer>
        ))}
      </StyledBenefitsContainer>
    </StyledContainer>
  );
}
