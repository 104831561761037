import React from 'react';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { StyledText } from '@goodfynd/react-web.typography.text';

import config from '../../../config';
import {
    StyledContainer, StyledContent, StyledContentWrapper, StyledFormContainer, StyledImage,
    StyledMobileImage
} from './styles';

import type { LandingPageFormProps } from '../types';
const LandingPageForm = ({
  desktop_image,
  background_image,
  form_headline,
  form_sub_headline,
  mobile_image,
  hubspot_form_id = '',
}: LandingPageFormProps): JSX.Element => {
  const portalId = config.env.HUBSPOT_PORTAL_ID || '';
  const hubspotFormShortId = hubspot_form_id.replaceAll('-', '');
  const formElementId = `hubSpotForm_${hubspotFormShortId}`;
  useHubspotForm({
    portalId: portalId,
    formId: hubspot_form_id,
    target: `#${formElementId}`,
  });
  return (
    <StyledContainer css={{ backgroundImage: `url(${background_image})` }}>
      <StyledContentWrapper>
        <StyledContent>
          <StyledImage css={{ backgroundImage: `url(${desktop_image})` }} />
          <StyledMobileImage
            css={{ backgroundImage: `url(${mobile_image || desktop_image})` }}
          />
          <StyledFormContainer>
            <StyledText type="h3">{form_headline}</StyledText>
            <StyledText type="body1">{form_sub_headline}</StyledText>
            <div id={formElementId}></div>
          </StyledFormContainer>
        </StyledContent>
      </StyledContentWrapper>
    </StyledContainer>
  );
};

export default LandingPageForm;
